import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

const INIT_PAGE = 0
const INIT_LIMIT = 6
const INIT_TOTAL_CNT = 0

export const componentState = () => {
  const route = useRoute()
  const router = useRouter()
  const { proxy } = getCurrentInstance()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    initCnt: 0,
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    totalCnt: INIT_TOTAL_CNT,
    isInit: false,
    isShow: false,
    searchText: route.query.search || '',
    sort: route.query.sort || 'RECOMMEND_DESC',
    params: computed({
      get: () => {
        return {
          page: state.page + 1,
          searchText: state.searchText,
          sort: state.sort
        }
      }
    }),
    list: []
  })

  const inputSearchText = e => {
    state.searchText = e.target.value
  }

  const fnListFunction = async () => {
    state.isShow = false
    const res = await proxy.$WizzpediaSvc.postGetMyCommentList(state.params)
    state.totalCnt = res.totalCnt
    if (res.resultCode === '0000') {
      state.page++
      state.list = state.list.concat(
        res.list.map(item => {
          return {
            ...item
          }
        })
      )
      state.isShow = true
    } else {
      state.isShow = false
    }
  }

  const fnSearch = () => {
    router.push({
      name: 'my-page-score-index',
      query: {
        ...route.query,
        search: state.searchText,
        sort: state.sort
      }
    })
  }

  const init = async () => {
    await fnListFunction()
    state.isInit = true
    state.isShow = true
  }
  init()
  return { ...toRefs(state), fnListFunction, fnSearch, inputSearchText }
}
