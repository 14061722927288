<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con">
          <div class="lump bo_zero">
            <!--210810 class추가-->
            <label class="input srh_type02">
              <input
                type="search"
                placeholder="작품명, 댓글내용 검색"
                :value="searchText"
                @input="inputSearchText"
                @keyup.enter="fnSearch"
              />
              <button
                v-if="searchText"
                class="btn_search delete"
                @click="() => (searchText = '')"
              ></button>
              <button class="btn_search" @click="fnSearch">검색</button>
            </label>
          </div>
          <div class="party_list_wrap">
            <div class="list_top" style="min-height: auto;">
              <!--210810 style 추가-->
              <div class="left">
                <button class="total">총 {{ totalCnt }}건</button>
              </div>
              <div class="right">
                <!--                            210713삭제-->
                <!--                            <label class="checkbox">-->
                <!--                                <input type="checkbox">-->
                <!--                                <span class="label"><span>종료 비노출</span></span>-->
                <!--                            </label>-->
                <label class="select">
                  <select v-model="sort" @change="fnSearch">
                    <option value="RECOMMEND_DESC">추천순</option>
                    <option value="SEQ_DESC">최신순</option>
                  </select>
                </label>
              </div>
            </div>
            <party-score-list :is-date="false" :list="list" />
            <observe-visible
              :key="initCnt"
              :page="page"
              :limit="limit"
              :totalCnt="totalCnt"
              @fetch="fnListFunction"
              v-show="isShow"
              v-if="isInit"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PartyScoreList from '@/components/views/party/score-list/index.vue'
import { componentState } from './index.js'
export default {
  name: 'my-page-score-index',
  components: { PartyScoreList },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
